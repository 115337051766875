.block-editor-iframe .block.iframe {
  .toolbar-inner {
    max-width: 350px;
    justify-content: space-between;

    > div {
      display: flex;
    }

    input {
      width: 100%;
    }

    button,
    input {
      border: none;
      background: none;

      &:focus-visible {
        outline: none;
      }
    }

    button {
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}

.block.iframe iframe {
  width: 100%;
}
